<script setup lang="ts">
import type Grid from 'muuri'
import type { Reminder } from '~/types/notification'
import { Permission } from '~/types/misc'

const pageTitle = ref('Dashboard')
const clinicStore = useClinicStore()
const userStore = useUserStore()
const nuxtApp = useNuxtApp()

const isGridVisible = ref(false)
const patientsCount = ref()
const appointmentsCount = ref()
const stockCount = ref()
const reminderSidebarType = ref<'all' | 'add' | Reminder | undefined>()
const staffCount = computed(() => clinicStore.staff?.length)
const userHasStockReadPermission = computed(() =>
  userStore.hasPermission(Permission.VIEW_STOCK),
)

let Muuri: Grid

useHead({
  title: pageTitle.value,
})

interface WidgetDataNumber {
  id: number
  title: string
  icon: string
  value: string | number | undefined
  link: string
  alertText?: string
  chip?: number | string
}

const currentMonth = new Date()
  .toLocaleString('pt-PT', { month: 'long' })
  .replace(/^\w/, (c) => c.toUpperCase())

const widgets = ref(
  [
    {
      id: 1,
      title: 'Pacientes',
      icon: 'i-heroicons-users-solid',
      value: patientsCount,
      link: '/patients/',
      chip: currentMonth,
    },
    {
      id: 2,
      title: 'Consultas',
      icon: 'i-heroicons-calendar-days-solid',
      value: appointmentsCount,
      link: '/calendar/',
      chip: currentMonth,
    },
    userHasStockReadPermission.value && {
      id: 3,
      title: 'Stock',
      icon: 'i-heroicons-archive-box-solid',
      value: stockCount,
      link: '/stock/',
      chip: currentMonth,
    },
    {
      id: 4,
      title: 'Staff',
      icon: 'i-heroicons-user-group-solid',
      value: staffCount,
      link: '/staff/',
    },
  ].filter(Boolean) as unknown as WidgetDataNumber[],
)

function openReminderSidebar(type: 'all' | 'add' | Reminder) {
  closeReminderSidebar()

  // Wait for the sidebar to close to avoid data conflicts
  nextTick(() => {
    if (type === 'add') {
      reminderSidebarType.value = 'add'
    } else if (type === 'all') {
      reminderSidebarType.value = 'all'
    } else {
      reminderSidebarType.value = type
    }
  })
}

function closeReminderSidebar() {
  reminderSidebarType.value = undefined
}

let alertShown = false
async function getCount() {
  await nuxtApp.$api
    .getDashboardStats()
    .then((result) => {
      patientsCount.value = result.patient_count
      appointmentsCount.value = result.appointment_count
      stockCount.value = result.stock_count

      // Show alert only once
      if (result.stock_expiring_or_expired && !alertShown) {
        const message = 'Existem produtos a expirar ou expirados.'

        widgets.value[2].alertText = message
        showToast('warning', 'Stock', message)
        alertShown = true
      }
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
}

getCount()

onMounted(async () => {
  await nextTick()
  Muuri = await initMuuri('dashboard-layout')
  checkLocalStorage('dashboard-layout', Muuri)
  Muuri.on('layoutEnd', () => {
    isGridVisible.value = true
    Muuri.refreshItems().layout()
  })
})

watch(
  () => [
    clinicStore.websocket.last_patient_change,
    clinicStore.websocket.last_appointment_change,
    clinicStore.websocket.last_stock_change,
  ],
  () => {
    getCount()
  },
)
</script>

<template>
  <div class="dashboard section container">
    <UiSubheader :title="pageTitle">
      <template #buttons>
        <div v-if="isGridVisible">
          <UDropdown
            :items="[
              [
                {
                  label: 'Redefinir Layout',
                  icon: 'i-heroicons-arrows-pointing-in',
                  click: () => {
                    resetLayout('dashboard-layout', Muuri)
                  },
                },
              ],
            ]"
          >
            <UButton trailing-icon="i-heroicons-ellipsis-vertical" />
          </UDropdown>
        </div>
      </template>
    </UiSubheader>

    <div v-if="!isGridVisible" class="grid grid-cols-1 gap-6 md:grid-cols-2">
      <UCard v-for="i in 4" :key="i">
        <USkeleton class="h-[200px]" />
      </UCard>
    </div>

    <div class="drag-container"></div>
    <div
      class="is-draggable grid-container -m-4"
      :style="isGridVisible ? 'visibility:visible' : 'visibility:hidden'"
    >
      <div
        v-for="widget in widgets"
        :key="widget.id"
        class="is-draggable w-full p-4 md:w-1/2"
        :class="userHasStockReadPermission ? 'lg:w-1/4' : 'lg:w-1/3'"
        :data-id="widget.id"
      >
        <WidgetDataNumber v-bind="widget" />
      </div>

      <div class="is-draggable w-full p-4 lg:w-1/2" data-id="5">
        <div>
          <WidgetReminders
            @refresh-layout="reRenderMuuri(Muuri)"
            @open-reminder-sidebar="openReminderSidebar($event)"
          />
        </div>
      </div>

      <div class="is-draggable w-full p-4 lg:w-1/2" data-id="6">
        <div>
          <WidgetScheduleListClinic @refresh-layout="reRenderMuuri(Muuri)" />
        </div>
      </div>
      <div
        v-if="userStore.hasPermission(Permission.VIEW_INVOICE)"
        class="is-draggable w-full p-4 lg:w-1/2"
        data-id="7"
      >
        <div>
          <WidgetInvoiceList
            title="Últimas Faturas"
            @refresh-layout="reRenderMuuri(Muuri)"
          />
        </div>
      </div>
      <div class="is-draggable w-full p-4 lg:w-1/2" data-id="8">
        <div>
          <WidgetLatestPatients @refresh-layout="reRenderMuuri(Muuri)" />
        </div>
      </div>
      <div class="is-draggable w-full p-4 lg:w-1/2" data-id="9">
        <div>
          <WidgetCarerList />
        </div>
      </div>
    </div>

    <transition name="slide-left">
      <LazyUiSidebarReminder
        v-if="reminderSidebarType"
        :type="reminderSidebarType"
        @change-open-reminder="openReminderSidebar($event)"
        @close-popup="closeReminderSidebar"
      />
    </transition>
  </div>
</template>
